<template>
<div>
	Click to button to test backend
	<test_component>

</test_component>
</div>

</template>

<script>
    import test_component from "../../../../shared/components/test";

    export default {
        name: "Test_Page",
        components: {test_component}
    }
</script>

<style scoped>

</style>
