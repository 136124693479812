<template>
	<button @click="getTests">Test</button>
</template>

<script>
    export default {
        name: "test",


        methods: {
            getTests() {
                this.$store.dispatch('test/getTest').then((response) => {
                    console.log(response)
                }).catch((error) => {
                    this.showErrorNotifications(error);
                });
            }

        }
    }
</script>

<style scoped>

</style>